<template>
  <button
    ref="localSettingsButton"
    data-test-settings-button
    class="
      flex
      justify-start
      items-center
      w-full
      btn btn-white
      uppercase
      tracking-wider
      text-sm
      h-12
    "
    @click="showing = true"
  >
    <!--    <inline-svg-->
    <!--      v-if="countryId"-->
    <!--      :name="flagSvg"-->
    <!--      classes="w-8 h-6"-->
    <!--      :title="countryName"-->
    <!--    ></inline-svg>-->

    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="countryId"
      class="fill-current w-10"
      :title="countryName"
      v-html="flag"
    ></span>
    <!--eslint-enable-->
    <span class="ml-2" data-currency-button>{{ currencyDesc }}</span>

    <Portal v-show="showing" selector="#modals">
      <CardModal
        background-classes="overflow-auto"
        card-classes="mb-4 container mx-auto max-w-3xl bg-white shadow-lg rounded-lg"
        :showing="showing"
        @close="close"
      >
        <template #header>
          <h2>{{ $t('navBar.preferences') }}</h2>
        </template>

        <LocalSettingsForm @close="close" />
      </CardModal>
    </Portal>
  </button>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LocalSettingsForm from './LocalSettingsForm'
// import { loadLanguageAsync } from '@/i18n'

async function importFlag(countryId) {
  const { default: svg } = await import(
    /* webpackChunkName: "svg-[request]" */ `html-loader!@/assets/flags/${countryId}.svg`
  )
  return svg
}

export default {
  components: {
    LocalSettingsForm,
  },
  data() {
    return {
      showing: false,
    }
  },
  computed: {
    ...mapState(['settings', 'flag']),
    ...mapGetters(['country', 'currency', 'isLargeScreen', 'locale']),
    countryId() {
      return this.country ? this.country.id.toLowerCase() : ''
    },
    countryName() {
      return this.country ? this.country.name : ''
    },
    currencyDesc() {
      return this.currency ? this.currency.symbol + ' ' + this.currency.id : ''
    },
  },
  watch: {
    countryId(countryId) {
      importFlag(countryId).then((svg) => {
        this.$store.state.flag = svg
      })
    },
    locale(locale) {
      this.setLocale(locale)
    },
  },
  methods: {
    close() {
      this.showing = false
      this.$refs.localSettingsButton.focus()
    },
    hide() {
      if (!this.isLargeScreen) {
        this.$root.$emit('bv::toggle::collapse', 'nav_collapse')
      }
    },
    setLocale(locale) {
      // this.$i18n.locale = locale
      // loadLanguageAsync(locale).then(() => {
      //   this.$i18n.locale = locale
      // })
      this.$moment.locale(locale)
      // this.$validator.localize(locale)
      this._updateBrowserAddress(locale)
    },
    _updateBrowserAddress(newLanguageCode) {
      const currentLocation = location.href
      const arrLocation = currentLocation.split('/')
      const oldLanguageCode = arrLocation[3]

      arrLocation.splice(3, 1, newLanguageCode)
      const newLocation = arrLocation.join('/')

      if (oldLanguageCode !== newLanguageCode) {
        window.history.pushState(
          currentLocation,
          this.$t('navBar.title'),
          newLocation,
        )
        document.title = this.$t('navBar.title')
        document.documentElement.lang = newLanguageCode
        document
          .getElementsByName('description')[0]
          .setAttribute('content', this.$t('navBar.description'))
      }
    },
  },
}
</script>
