<template>
  <div class="pt-2">
    <nav role="navigation" class="flex justify-between items-center flex-wrap">
      <div class="flex items-center flex-shrink-0">
        <button
          aria-label="Gambia flights home page"
          class="
            flex
            h-12
            btn btn-white
            justify-center
            items-center
            text-xl
            font-semibold
            md:text-2xl
            pl-0
            whitespace-no-wrap
          "
          :title="$t('navBar.title')"
          @click="
            $router.push('/' + locale)
            goHome()
          "
        >
          <InlineSvg
            name="airplane"
            classes="w-5 h-5 inline items-center rotate-90 fill-current"
          ></InlineSvg>
          <span class="ml-2">Gambia-flights.com</span>
        </button>
      </div>

      <div class="hidden md:flex md:block md:justify-end ml-auto">
        <div id="authInfo">
          <UserDropdownButton v-if="isAuth" />

          <div v-else class="flex h-full">
            <button
              aria-label="Login"
              class="
                flex
                items-center
                btn btn-white
                ml-2
                uppercase
                tracking-wider
                text-sm
              "
              @click="
                $router.push('/' + locale + '/login')
                $refs.localSettingsButton.hide()
              "
            >
              <InlineSvg
                name="sign-in-alt"
                classes="items-center w-4 h-4 mr-2"
              ></InlineSvg>
              {{ $t('auth.login') }}
            </button>

            <button
              aria-label="Register"
              class="
                flex
                items-center
                btn btn-white
                ml-2
                uppercase
                tracking-wider
                text-sm
              "
              @click="
                $router.push('/' + locale + '/register')
                $refs.localSettingsButton.hide()
              "
            >
              <InlineSvg
                name="sign-in-alt"
                classes="items-center w-4 h-4 mr-2"
              ></InlineSvg>
              <span class="items-center">{{ $t('auth.register') }}</span>
            </button>
          </div>
        </div>

        <div class="ml-2">
          <LocalSettingsButton ref="localSettingsButton" />
        </div>
      </div>

      <button
        aria-label="Menu"
        class="
          md:hidden
          btn btn-white
          text-gray-700
          bg-gray-200
          border border-gray-400
        "
        @click="isMenuExpanded = !isMenuExpanded"
      >
        <InlineSvg name="bars" classes="w-5 h-5"></InlineSvg>
      </button>

      <Transition name="fade">
        <div v-if="isMenuExpanded" class="md:hidden mt-2 block w-full">
          <UserDropdownButton v-if="isAuth" />

          <div v-else class="block">
            <button
              aria-label="Login"
              class="
                my-2
                h-12
                w-full
                flex
                btn btn-white
                uppercase
                tracking-wider
                text-sm
              "
              @click="
                $router.push('/' + locale + '/login')
                $refs.localSettingsButtonDropdown.hide()
              "
            >
              <InlineSvg
                name="sign-in-alt"
                classes="items-center w-4 h-4 mr-2"
              ></InlineSvg>
              {{ $t('auth.login') }}
            </button>

            <button
              aria-label="Register"
              class="
                my-2
                h-12
                w-full
                flex
                btn btn-white
                uppercase
                tracking-wider
                text-sm
              "
              @click="
                $router.push('/' + locale + '/register')
                $refs.localSettingsButtonDropdown.hide()
              "
            >
              <InlineSvg
                name="sign-in-alt"
                classes="items-center w-4 h-4 mr-2"
              ></InlineSvg>
              <div class="items-center">{{ $t('auth.register') }}</div>
            </button>

            <div class="my-2 h-12 block w-full">
              <LocalSettingsButton ref="localSettingsButtonDropdown" />
            </div>
          </div>
        </div>
      </Transition>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScreenMixin from './mixins/ScreenMixin.js'
import LocalSettingsButton from './LocalSettingsButton'
import UserDropdownButton from './UserDropdownButton'

export default {
  components: {
    LocalSettingsButton,
    UserDropdownButton,
  },
  mixins: [ScreenMixin],
  data() {
    return {
      isMenuExpanded: false,
    }
  },
  computed: {
    ...mapGetters(['isAuth', 'locale', 'isLargeScreen']),
  },
  methods: {
    goHome() {
      this.$store.commit('setStatus', 'Initial')
    },
    hide() {
      if (!this.isLargeScreen) {
        this.$root.$emit('bv::toggle::collapse', 'nav_collapse')
      }
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
