/* eslint no-prototype-builtins: 0 */

// let t0 = performance.now()
// let t1 = performance.now()
// console.log('Time taken: ' + (t1 - t0) + ' milliseconds')

import Vue from 'vue'
import Vuex from 'vuex'
import _sortBy from 'lodash.sortby'
import _union from 'lodash.union'
import _unionBy from 'lodash.unionby'
import _mapValues from 'lodash.mapvalues'

Vue.use(Vuex)

const _ = {
  sortBy: _sortBy,
  union: _union,
  unionBy: _unionBy,
  mapValues: _mapValues,
}

const moment = require('moment')

function minutesAfterMidnight(datetime) {
  const matches = datetime.match(/T(\d{2}):(\d{2})/)

  if (!matches) {
    return 89898989898989 // Should not need this!!
  }
  return parseInt(matches[1]) * 60 + parseInt(matches[2])
}

function setLegs(legs) {
  for (const legId in legs) {
    let departAirportCode = ''
    let arriveAirportCode = ''
    let previousArriveAirportCode = ''
    let sameConnectionAirports = true
    const leg = legs[legId]
    const segmentIds = leg.SegmentIds

    segmentIds.forEach((segmentId) => {
      departAirportCode = segmentId.substring(0, 3)
      arriveAirportCode = segmentId.slice(-3)

      if (
        previousArriveAirportCode !== '' &&
        previousArriveAirportCode !== departAirportCode
      ) {
        sameConnectionAirports = false
      }

      previousArriveAirportCode = arriveAirportCode
    })

    leg.sameConnectionAirports = sameConnectionAirports
    leg.arrival = parseInt(legId.slice(-10))
    leg.departure = parseInt(legId.substring(4, 14))
    leg.departureMinutes = minutesAfterMidnight(leg.Departure)
    leg.arrivalMinutes = minutesAfterMidnight(leg.Arrival)
    leg.stops = leg.Stops.length
    // leg.distinctOperatingCarriers = _.difference(leg.Carriers, leg.OperatingCarriers)
  }

  return legs
}

function colourCode(priceObjects) {
  if (!Object.keys(priceObjects).length) {
    return
  }

  let prices = Object.keys(priceObjects).map(
    (priceObject) => priceObjects[priceObject].price,
  )

  prices = prices.filter((price) => {
    return price > 0
  })
  prices.sort((a, b) => a - b)

  const lowestPrice = prices[0]
  const highestPrice = prices[prices.length - 1]
  const priceDiffThird = (highestPrice - lowestPrice) / 3
  const lowThreshold = lowestPrice + priceDiffThird
  const medThreshold = lowestPrice + priceDiffThird * 2

  for (const priceObject in priceObjects) {
    if (priceObjects[priceObject].price < lowThreshold) {
      priceObjects[priceObject].class = 'green'
      continue
    }
    if (priceObjects[priceObject].price > medThreshold) {
      priceObjects[priceObject].class = 'red'
    }
  }
}

function colourPrices(dates) {
  if (!Object.keys(dates).length) {
    return
  }

  const directPrices = []
  const indirectPrices = []

  for (const date in dates) {
    const flight = dates[date]

    if (flight && flight.direct && flight.direct.price !== null) {
      directPrices[date] = {
        price: flight.direct.price,
        class: 'amber',
        quoted: flight.direct.quoted,
      }
    }

    if (flight && flight.indirect && flight.indirect.price !== null) {
      indirectPrices[date] = {
        price: flight.indirect.price,
        class: 'amber',
        quoted: flight.indirect.quoted,
      }
    }
  }

  // console.log(dates)

  colourCode(directPrices)
  colourCode(indirectPrices)

  for (const date in dates) {
    const flight = dates[date]

    if (
      flight &&
      flight.direct &&
      flight.direct.price !== null &&
      !isNaN(flight.direct.price)
    ) {
      flight.direct = directPrices[date]
    }

    if (
      flight &&
      flight.indirect &&
      flight.indirect.price !== null &&
      !isNaN(flight.indirect.price)
    ) {
      flight.indirect = indirectPrices[date]
    }
  }
}

function createLookupObject(arrayOfObjects, key) {
  const lookupObj = {}

  for (let i = 0; i < arrayOfObjects.length; i++) {
    lookupObj[arrayOfObjects[i][key]] = arrayOfObjects[i]
  }

  return lookupObj
}

function mergePricingOptions(state, flights) {
  const newPricingOptions = flights.PricingOptions
  const existingPricingOptions = { ...state.flights.PricingOptions }
  const usd =
    typeof state.flights.Currency.usd === 'undefined'
      ? flights.Currency.usd
      : state.flights.Currency.usd

  for (const i in newPricingOptions) {
    const pricingOptions = newPricingOptions[i]
    const existingPricingOption = existingPricingOptions[i] || null

    for (let j = 0; j < pricingOptions.length; j++) {
      pricingOptions[j].Price = pricingOptions[j].Price / usd
    }

    if (existingPricingOption) {
      newPricingOptions[i] = _.union(pricingOptions, existingPricingOption)
    }

    newPricingOptions[i] = _.sortBy(newPricingOptions[i], 'Price')
  }

  return { ...state.flights.PricingOptions, ...newPricingOptions }
}

function mergeAgents(state, flights) {
  const agents = { ...state.flights.Agents, ...flights.Agents }
  let agentsCombinationAdded = false

  for (let i = 0; i < agents.length; i++) {
    if (agents[i].Id === 9999999) {
      agentsCombinationAdded = true
      break
    }
  }

  if (!agentsCombinationAdded) {
    agents[9999999] = {
      Id: 9999999,
      ImageUrl: '',
      Name: 'combine',
      OptimisedForMobile: false,
      Status: 'UpdatesComplete',
      Type: 'TravelAgent',
    }
  }

  return agents
}

function mergeItineraries(state, flights, legs, pricingOptions) {
  const isRoundTrip = state.criteria.tripType === 'roundTrip'
  const existingItineraries = { ...state.flights.Itineraries }
  let addedAirlineCombinations = false
  const flightStops = { ...state.flights.flightStops }
  let scores = { ...state.flights.scores }
  const outboundOrigins = state.flights.outboundOrigins
    ? new Set(state.flights.outboundOrigins)
    : new Set()
  const inboundOrigins = state.flights.inboundOrigins
    ? new Set(state.flights.inboundOrigins)
    : new Set()
  const carriers = { ...state.flights.Carriers, ...flights.Carriers }

  for (const i in flights.Itineraries) {
    const itinerary = flights.Itineraries[i]
    let uniqueCarriers = []
    let carrierId
    const existingItinerary = existingItineraries[itinerary.Id] || null
    const outboundLeg = legs[itinerary.OutboundLegId]
    const inboundLeg = isRoundTrip ? legs[itinerary.InboundLegId] : null

    // if (!outboundLeg) {
    //   console.log(itinerary)
    // }
    // if (isRoundTrip && !inboundLeg) {
    //   console.log(itinerary)
    // }

    if (existingItinerary) {
      itinerary.BookingOptions = _.union(
        itinerary.BookingOptions,
        existingItinerary.BookingOptions,
      )
    }
    // itinerary.operatingCarrierCount = 0

    // Dept and Arrive times
    itinerary.outboundDeparture = outboundLeg.departure // parseInt(outboundLegId.substring(4, 14))
    itinerary.outboundArrival = outboundLeg.arrival // parseInt(outboundLegId.slice(-10))

    if (isRoundTrip) {
      itinerary.inboundDeparture = inboundLeg.departure // parseInt(inboundLegId.substring(4, 14))
      itinerary.inboundArrival = inboundLeg.arrival // parseInt(inboundLegId.slice(-10))
    }

    // Airports
    const outboundOrigin = outboundLeg.OriginStation // outboundLegId.substring(0, 3)
    const outboundDestination = outboundLeg.DestinationStation // outboundLegId.slice(-14, -1)
    outboundOrigins.add(outboundOrigin)

    if (isRoundTrip) {
      const inboundOrigin = inboundLeg.OriginStation // inboundLegId.substring(0, 3)
      const inboundDestination = inboundLeg.DestinationStation // inboundLegId.slice(-14, -11)
      inboundOrigins.add(inboundOrigin)

      itinerary.sameOrigins = outboundOrigin === inboundDestination
      itinerary.sameDestinations = outboundDestination === inboundOrigin
      itinerary.sameOutboundAndInbound =
        itinerary.sameOrigins && itinerary.sameDestinations
    }

    // Unique airports
    outboundOrigins.forEach((airportCode) => {
      if (
        !state.filters.outbound.origins.find(
          (origin) => airportCode === origin.code,
        )
      ) {
        state.filters.outbound.origins.push({
          code: airportCode,
          active: true,
        })
      }
    })

    if (isRoundTrip) {
      inboundOrigins.forEach((airportCode) => {
        if (
          !state.filters.inbound.origins.find(
            (origin) => airportCode === origin.code,
          )
        ) {
          state.filters.inbound.origins.push({
            code: airportCode,
            active: true,
          })
        }
      })
    }

    // Change airport during connection?
    itinerary.sameConnectionAirports = isRoundTrip
      ? outboundLeg.sameConnectionAirports && inboundLeg.sameConnectionAirports
      : outboundLeg.sameConnectionAirports

    // Total duration
    itinerary.duration = outboundLeg.Duration
    if (isRoundTrip) {
      itinerary.duration = Math.round(
        (itinerary.duration + inboundLeg.Duration) / 2,
      )
    }

    // Carriers
    if (isRoundTrip) {
      uniqueCarriers = [
        ...new Set(outboundLeg.Carriers.concat(inboundLeg.Carriers)),
      ]
    } else {
      uniqueCarriers = [...new Set(outboundLeg.Carriers)]
    }

    itinerary.carrierIds = uniqueCarriers

    if (itinerary.carrierIds.length === 1) {
      carrierId = itinerary.carrierIds[0]
      itinerary.airline = carriers[carrierId].Name
    } else {
      carrierId = 99999
      itinerary.airline = 'combination'

      if (!addedAirlineCombinations) {
        carriers[carrierId] = {
          Id: carrierId,
          Name: 'combination',
          ImageUrl: '',
        }

        addedAirlineCombinations = true
      }
    }

    // Price
    itinerary.BookingOptions.forEach((bookingOption) => {
      bookingOption.price = 0

      bookingOption.BookingItems.forEach((bookingItem) => {
        // if (!pricingOptions[bookingItem.PricingId]) {
        //   console.log('no pricing item: ' + bookingItem.PricingId)
        //   console.log(itinerary)
        // }
        bookingOption.price += pricingOptions[bookingItem.PricingId][0].Price
      })
    })

    itinerary.BookingOptions = _.sortBy(itinerary.BookingOptions, 'price')
    itinerary.price = itinerary.BookingOptions[0].price

    // Total stops
    const outboundStops = legs[itinerary.OutboundLegId].stops // parseInt(outboundLegId.slice(-16, -15))
    let inboundStops

    itinerary.stops = outboundStops

    if (isRoundTrip) {
      inboundStops = legs[itinerary.InboundLegId].stops // parseInt(inboundLegId.slice(-16, -15))
      itinerary.stops += inboundStops
    }

    const directFlight = itinerary.stops === 0
    const oneStopFlight = isRoundTrip
      ? itinerary.stops === 1 || (outboundStops === 1 && inboundStops === 1)
      : itinerary.stops === 1

    if (directFlight) {
      if (itinerary.price < flightStops.direct.minPrice) {
        flightStops.direct.minPrice = itinerary.price
      }
      flightStops.direct.count++
    } else if (oneStopFlight) {
      if (itinerary.price < flightStops.oneStop.minPrice) {
        flightStops.oneStop.minPrice = itinerary.price
      }
      flightStops.oneStop.count++
    } else {
      if (itinerary.price < flightStops.twoPlusStops.minPrice) {
        flightStops.twoPlusStops.minPrice = itinerary.price
      }
      flightStops.twoPlusStops.count++
    }

    scores = {
      minPrice:
        itinerary.price < scores.minPrice ? itinerary.price : scores.minPrice,
      maxPrice:
        itinerary.price > scores.maxPrice ? itinerary.price : scores.maxPrice,
      minDuration:
        itinerary.duration < scores.minDuration
          ? itinerary.duration
          : scores.minDuration,
      maxDuration:
        itinerary.duration > scores.maxDuration
          ? itinerary.duration
          : scores.maxDuration,
      minStops:
        itinerary.stops < scores.minStops ? itinerary.stops : scores.minStops,
      maxStops:
        itinerary.stops > scores.maxStops ? itinerary.stops : scores.maxStops,
    }
  }

  const itineraries = { ...state.flights.Itineraries, ...flights.Itineraries }

  return {
    carriers,
    flightStops,
    itineraries,
    outboundOrigins: [...outboundOrigins],
    inboundOrigins: [...inboundOrigins],
    scores,
  }
}

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      name: 'Mark Thompson',
      tipCounter: -1,
    },
    nearestAirports: [],
    settings: {
      countryId: 'UK',
      currencyId: 'GBP',
      languageId: navigator.language,
    },
    countries: {},
    languages: {},
    currencies: {},
    flag: '',
    criteria: {
      queryId: '',
      tripType: 'roundTrip',
      origin: {
        code: '',
        name: '',
      },
      destination: {
        code: 'BJL',
        name: 'Banjul',
      },
      calendarType: 'specific',
      outboundDate: null,
      inboundDate: null,
      cabinClass: 'Economy',
      adults: 1,
      children: [],
      sortValue: 'score',
    },
    status: 'Initial',
    error: {},
    flights: {
      Agents: {},
      Carriers: {},
      Currency: [],
      Itineraries: {},
      Legs: {},
      Places: {},
      PricingOptions: {},
      Segments: {},
      Query: [],
      flightStops: {
        direct: {
          count: 0,
          minPrice: 999999,
        },
        oneStop: {
          count: 0,
          minPrice: 999999,
        },
        twoPlusStops: {
          count: 0,
          minPrice: 999999,
        },
      },
      scores: {
        minDuration: 15000,
        maxDuration: 0,
        minPrice: 15000,
        maxPrice: 0,
        minStops: 10,
        maxStops: 0,
      },
      RequestCount: 0,
    },
    browse: {
      browseId: 0,
      flights: {},
      flightsAnyTime: {},
      cheapest: {
        direct: [],
        indirect: [],
      },
      cheapestAnyTime: {
        direct: [],
        indirect: [],
      },
    },
    deals: [],
    dealsAirports: [
      'LGW',
      'MAN',
      'BHX',
      'AMS',
      'BCN',
      'BRU',
      'LPA',
      'DKRA',
      'FRA',
      'DUS',
      'CDG',
      'MUC',
    ],
    routes: [],
    filters: {
      direct: false,
      isChart: false,
      outboundEarliest: null,
      inboundLatest: null,
      showFilters: false,
      outbound: {
        origin: {
          arrival: {
            min: 0,
            max: 1439,
          },
          departure: {
            min: 0,
            max: 1439,
          },
        },
        origins: [],
      },
      inbound: {
        origin: {
          arrival: {
            min: 0,
            max: 1439,
          },
          departure: {
            min: 0,
            max: 1439,
          },
          departureDate: {
            min: null,
            max: null,
          },
        },
        origins: [],
      },
      durationMax: 10000,
      nights: {
        max: 0,
        min: 0,
      },
      stopTypes: {
        direct: true,
        one_stop: true,
        two_plus_stops: true,
      },
      agents: [],
      carriers: [],
      sameOutboundAndInbound: false,
      sameConnectionAirports: false,
    },
    showSearchControls: true,
    itineraryId: '',
    screenWidth: 0,
    validLocales: ['da', 'de', 'en', 'fr', 'es', 'it', 'nb', 'nl', 'pt', 'sv'],
    // meta: {
    //   title: Vue.$t('navBar.title')
    // }
  },
  getters: {
    initSettings: (state) => {
      const localSettings = global.localStorage
        ? localStorage.getItem('settings')
        : null

      return localSettings ? JSON.parse(localSettings) : state.settings
    },
    initLocale: (state, getters) => {
      const locale = getters.initSettings.languageId.substring(0, 2)

      return state.validLocales.includes(locale) ? locale : 'en'
    },
    api: (state, getters) => {
      const settings = getters.initSettings

      return `/api/${settings.countryId}/${settings.currencyId}/${settings.languageId}`
    },
    isRoundTrip: (state) => {
      return state.criteria.tripType === 'roundTrip'
    },
    isLargeScreen: (state) => state.screenWidth > 767,
    isAuth: (state) => !!state.user.email,
    country: (state) => {
      if (!state.settings.countryId) return

      return state.countries[state.settings.countryId]
    },
    language: (state) => {
      if (!state.settings.languageId) return

      const language = state.languages[state.settings.languageId]

      if (!language) {
        return state.languages['en-GB']
      }

      return language
    },
    locale: (state) => state.settings.languageId.substring(0, 2),
    validLanguages: (state) => {
      return Object.keys(state.languages)
    },
    currency: (state) => {
      if (!state.settings.currencyId) return

      return state.currencies[state.settings.currencyId]
    },
    outboundEarliestGetter: (state) => {
      const outboundDate = state.criteria.outboundDate
      if (outboundDate === 'anytime') {
        return
      }

      const outboundStartOfMonth = moment(outboundDate).startOf('month')
      const outboundEndOfMonth = moment(outboundDate).endOf('month')

      if (!state.filters.outboundEarliest) {
        return outboundStartOfMonth.format('YYYY-MM-DD')
      }

      const outboundEarliest = moment(state.filters.outboundEarliest)

      if (
        outboundEarliest.isBefore(outboundStartOfMonth) ||
        outboundEarliest.isAfter(outboundEndOfMonth)
      ) {
        return outboundStartOfMonth.format('YYYY-MM-DD')
      }

      return state.filters.outboundEarliest
    },
    inboundLatestGetter: (state, getters) => {
      const inboundDate = state.criteria.inboundDate

      if (!getters.isRoundTrip || inboundDate === 'anytime') {
        return
      }

      const inboundStartOfMonth = moment(inboundDate).startOf('month')
      const inboundEndOfMonth = moment(inboundDate).endOf('month')

      if (!state.filters.inboundLatest) {
        return inboundStartOfMonth.format('YYYY-MM-DD')
      }

      const inboundEarliest = moment(state.filters.inboundLatest)

      if (
        inboundEarliest.isBefore(inboundStartOfMonth) ||
        inboundEarliest.isAfter(inboundEndOfMonth)
      ) {
        return inboundStartOfMonth.format('YYYY-MM-DD')
      }

      return state.filters.inboundLatest
    },
    itinerariesCount: (state) => {
      return Object.keys(state.flights.Itineraries).length
    },
    directOrIndirect: (state) => {
      return state.filters.direct ? 'direct' : 'indirect'
    },
    filteredItineraries: (state, getters) => {
      // var t0 = performance.now()

      if (getters.itinerariesCount === 0) {
        return []
      }

      const isRoundTrip = getters.isRoundTrip
      const filtered = []

      itinerariesLoop: for (const i in state.flights.Itineraries) {
        const itinerary = state.flights.Itineraries[i]
        const outboundLeg = state.flights.Legs[itinerary.OutboundLegId]
        const inboundLeg = isRoundTrip
          ? state.flights.Legs[itinerary.InboundLegId]
          : null

        // if (!outboundLeg) {
        //   console.log('itinerariesLoop outbound:')
        //   console.log(itinerary.OutboundLegId)
        //   console.log(state.flights.Legs)
        // }

        // No. of outbound stops
        if (!state.filters.stopTypes.direct) {
          if (itinerary.stops === 0) {
            continue
          }
        }
        if (!state.filters.stopTypes.one_stop) {
          if (isRoundTrip) {
            if (
              itinerary.stops === 1 ||
              (outboundLeg.stops === 1 && inboundLeg.stops === 1)
            ) {
              continue
            }
          }
          if (itinerary.stops === 1 || outboundLeg.stops === 1) {
            continue
          }
        }
        if (!state.filters.stopTypes.two_plus_stops) {
          if (isRoundTrip) {
            if (
              itinerary.stops > 1 &&
              (outboundLeg.stops > 1 || inboundLeg.stops > 1)
            ) {
              continue
            }
          } else {
            if (itinerary.stops > 1) {
              continue
            }
          }
        }

        // Total duration
        const durationMax = state.filters.durationMax

        if (itinerary.duration > durationMax) {
          continue
        }

        // Outbound: departure times
        const outboundDepartureMin = state.filters.outbound.origin.departure.min
        const outboundDepartureMax = state.filters.outbound.origin.departure.max

        if (
          outboundLeg.departureMinutes < outboundDepartureMin ||
          outboundLeg.departureMinutes > outboundDepartureMax
        ) {
          continue
        }

        // Outbound: arrival times
        const outboundArrivalMin = state.filters.outbound.origin.arrival.min
        const outboundArrivalMax = state.filters.outbound.origin.arrival.max

        if (
          outboundLeg.arrivalMinutes < outboundArrivalMin ||
          outboundLeg.arrivalMinutes > outboundArrivalMax
        ) {
          continue
        }

        // Inbound: departure times
        if (isRoundTrip) {
          const inboundLeg = state.flights.Legs[itinerary.InboundLegId]
          const inboundDepartureMin = state.filters.inbound.origin.departure.min
          const inboundDepartureMax = state.filters.inbound.origin.departure.max

          if (
            inboundLeg.departureMinutes < inboundDepartureMin ||
            inboundLeg.departureMinutes > inboundDepartureMax
          ) {
            continue
          }

          // Inbound: arrival times
          const inboundArrivalMin = state.filters.inbound.origin.arrival.min
          const inboundArrivalMax = state.filters.inbound.origin.arrival.max

          if (
            inboundLeg.arrivalMinutes < inboundArrivalMin ||
            inboundLeg.arrivalMinutes > inboundArrivalMax
          ) {
            continue
          }
        }

        // Carriers
        const carriers = state.filters.carriers

        for (const carrierId in carriers) {
          const carrier = carriers[carrierId]

          if (itinerary.carrierIds.length === 1) {
            // Individual airlines
            if (!carrier.active && carrier.Id === itinerary.carrierIds[0]) {
              continue itinerariesLoop
            }
          } else {
            // Airline combinations
            if (!carrier.active && carrier.Id === 99999) {
              continue itinerariesLoop
            }
          }
        }

        // Airports
        const filterOutboundOrigins = state.filters.outbound.origins
        const sameOutboundAndInbound = state.filters.sameOutboundAndInbound

        for (let j = 0; j < filterOutboundOrigins.length; j++) {
          if (
            !filterOutboundOrigins[j].active &&
            filterOutboundOrigins[j].code === outboundLeg.OriginStation
          ) {
            continue itinerariesLoop
          }
        }

        if (isRoundTrip && sameOutboundAndInbound) {
          if (!itinerary.sameOutboundAndInbound) {
            continue
          }
        }

        // Change airports during connection?
        if (state.filters.sameConnectionAirports) {
          if (!itinerary.sameConnectionAirports) {
            continue
          }
        }

        filtered.push(itinerary)
      }

      // var t1 = performance.now()
      // console.log('Time taken: ' + (t1 - t0) + ' milliseconds')

      return filtered
    },
    directItineraries: (state, getters) => {
      if (getters.itinerariesCount === 0) {
        return []
      }

      const directOnly = []

      for (const i in state.flights.Itineraries) {
        const itinerary = state.flights.Itineraries[i]

        if (itinerary.stops > 0) {
          continue
        }

        directOnly.push(itinerary)
      }

      return directOnly
    },
    filteredItinerariesCount: (state, getters) => {
      return getters.filteredItineraries.length
    },
    sortedItineraries: (state, getters) => {
      if (!getters.filteredItinerariesCount) {
        return []
      }

      return _.sortBy(getters.filteredItineraries, state.criteria.sortValue)
    },
    filteredRoutes: (state, getters) => {
      if (!state.routes) {
        return []
      }

      const routes = state.routes.filter((route) => {
        if (state.filters.direct) {
          return route.hasOwnProperty('direct')
        }
        return true
      })

      return _.sortBy(routes, getters.directOrIndirect)
    },
    filteredQuotes: (state, getters) => {
      if (
        !state.browse.cheapest ||
        !state.browse.cheapest[getters.directOrIndirect]
      ) {
        return []
      }

      const outboundEarliest = state.filters.outboundEarliest
      const inboundLatest = state.filters.inboundLatest
      const nightsMax = state.filters.nights.max
      const nightsMin = state.filters.nights.min

      if (!nightsMin && !nightsMax && !outboundEarliest && !inboundLatest) {
        return state.browse.cheapest[getters.directOrIndirect]
      }

      // Min/max nights
      var filteredQuotes = state.browse.cheapest[
        getters.directOrIndirect
      ].filter((quote) => {
        const nights = quote.nights

        if (nightsMin && nightsMax) {
          return nightsMin <= nights && nightsMax >= nights
        }

        if (nightsMin) {
          return nightsMin <= nights
        }

        if (nightsMax) {
          return nightsMax >= nights
        }

        return true
      })

      if (outboundEarliest) {
        filteredQuotes = filteredQuotes.filter((quote) => {
          return quote.outboundDate >= outboundEarliest
        })
      }

      if (getters.isRoundTrip && inboundLatest) {
        filteredQuotes = filteredQuotes.filter((quote) => {
          return inboundLatest >= quote.inboundDate
        })
      }

      return filteredQuotes
    },
    filteredQuotesCount: (state, getters) => {
      if (!getters.filteredQuotes) {
        return 0
      }

      return getters.filteredQuotes.length
    },
    legDurations: (state, getters) => {
      if (getters.itinerariesCount === 0) {
        return {
          min: 0,
          max: 0,
        }
      }

      let durationMin = 15000
      let durationMax = 0
      const isRoundTrip = getters.isRoundTrip

      for (const i in state.flights.Itineraries) {
        const itinerary = state.flights.Itineraries[i]
        const outboundLeg = state.flights.Legs[itinerary.OutboundLegId]
        let duration = outboundLeg.Duration

        if (isRoundTrip) {
          const inboundLeg = state.flights.Legs[itinerary.InboundLegId]

          duration = Math.round(
            (outboundLeg.Duration + inboundLeg.Duration) / 2,
          )
        }

        durationMin = duration < durationMin ? duration : durationMin
        durationMax = duration > durationMax ? duration : durationMax
      }

      Vue.set(state.filters, 'durationMax', durationMax)

      return {
        min: durationMin,
        max: durationMax,
      }
    },
    filtersApplied: (state, getters) => {
      if (getters.itinerariesCount === 0) {
        return
      }

      return getters.itinerariesCount !== getters.filteredItinerariesCount
    },
    agentsSearched: (state) => {
      const agentsCount = Object.keys(state.flights.Agents).length

      let checkedAgentsCount = 0
      let thisAgent
      let agentId

      if (!agentsCount) {
        return {
          name: '',
          count: checkedAgentsCount,
          total: agentsCount,
        }
      }

      for (agentId in state.flights.Agents) {
        const agent = state.flights.Agents[agentId]

        if (agent.Status === 'UpdatesComplete') {
          checkedAgentsCount++
        } else {
          // UpdatesPending
          if (typeof thisAgent === 'undefined') {
            thisAgent = agent
          }
        }
      }

      if (thisAgent) {
        return {
          name: thisAgent.Name,
          count: checkedAgentsCount,
          total: agentsCount,
        }
      }

      // Pretend to be searching the last agent returned from local deals...
      thisAgent = state.flights.Agents[agentId]

      if (thisAgent) {
        return {
          name: thisAgent.Name,
          count: checkedAgentsCount,
          total: agentsCount,
        }
      } else {
        return {
          name: '',
          count: checkedAgentsCount,
          total: agentsCount,
        }
      }
    },
    agentFilters: (state) => {
      return _.sortBy(state.filters.agents, '9999999')
    },
    carrierFilters: (state) => {
      return _.sortBy(state.filters.carriers, 'MinPrice')
    },
    outboundPrice: (state) => {
      const outboundDate = state.criteria.outboundDate
      const directOrIndirect = state.filters.direct ? 'direct' : 'indirect'
      const flights = state.browse.flights

      if (
        !flights ||
        !flights[outboundDate] ||
        !flights[outboundDate][directOrIndirect]
      ) {
        return
      }

      return flights[outboundDate][directOrIndirect].price
    },
    inboundPrice: (state) => {
      const outboundDate = state.criteria.outboundDate
      const inboundDate = state.criteria.inboundDate
      const directOrIndirect = state.filters.direct ? 'direct' : 'indirect'

      if (
        !state.browse.flights ||
        !state.browse.flights[outboundDate] ||
        !state.browse.flights[outboundDate].inbound ||
        !state.browse.flights[outboundDate].inbound[inboundDate] ||
        !state.browse.flights[outboundDate].inbound[inboundDate][
          directOrIndirect
        ]
      ) {
        return
      }

      const flights = state.browse.flights[outboundDate].inbound

      return flights[inboundDate][directOrIndirect].price
    },
    selectedItinerary: (state, getters) => {
      if (!state.itineraryId || !getters.filteredItinerariesCount) {
        return
      }

      return state.flights.Itineraries[state.itineraryId]
    },
    quoted: (state, getters) => {
      const outboundDate = state.criteria.outboundDate

      if (
        !state.browse.flights ||
        !outboundDate ||
        outboundDate === 'anytime'
      ) {
        return
      }

      if (
        !state.browse.flights[outboundDate] ||
        !state.browse.flights[outboundDate][getters.directOrIndirect]
      ) {
        return 'noSingle'
      }

      let quoteDate =
        state.browse.flights[outboundDate][getters.directOrIndirect].quoted

      if (state.criteria.tripType === 'roundTrip') {
        const inboundDate = state.criteria.inboundDate
        const inbound = state.browse.flights[outboundDate].inbound

        if (
          !inboundDate ||
          !inbound[inboundDate] ||
          !inbound[inboundDate][getters.directOrIndirect]
        ) {
          return 'noReturn'
        }

        quoteDate = inbound[inboundDate][getters.directOrIndirect].quoted
      }

      if (!quoteDate) return

      return moment(quoteDate).fromNow()
    },
    best: (state, getters) => {
      if (!getters.filteredItinerariesCount) {
        return
      }

      return _.sortBy(getters.filteredItineraries, 'score')[0]
    },
    cheapest: (state, getters) => {
      if (!getters.filteredItinerariesCount) {
        return
      }

      return _.sortBy(getters.filteredItineraries, 'price')[0]
    },
    cheapestDirect: (state, getters) => {
      if (!getters.directItineraries.length) {
        return
      }

      return _.sortBy(getters.directItineraries, 'price')[0]
    },
    fastest: (state, getters) => {
      if (!getters.filteredItinerariesCount) {
        return
      }

      return _.sortBy(getters.filteredItineraries, 'duration')[0]
    },
  },
  actions: {
    increment({ commit }) {
      commit('increment')
    },
    saveSettings({ commit, getters }, settings) {
      if (!getters.validLanguages.includes(settings.languageId)) {
        settings.languageId = 'en-GB'
      }
      commit('setSettings', settings)
    },
  },
  mutations: {
    setAuthenticatedUser(state, user) {
      Vue.set(state, 'user', user)
    },
    setSettings(state, settings) {
      Vue.set(state, 'settings', settings)
    },
    setCountries(state, countries) {
      Vue.set(state, 'countries', createLookupObject(countries, 'id'))
    },
    setLanguages(state, languages) {
      Vue.set(state, 'languages', createLookupObject(languages, 'id'))
    },
    setCurrencies(state, currencies) {
      Vue.set(state, 'currencies', createLookupObject(currencies, 'id'))
    },
    setNearestAirports(state, nearestAirports) {
      Vue.set(state, 'nearestAirports', nearestAirports)
    },
    setQueryId(state, queryId) {
      Vue.set(state.criteria, 'queryId', queryId)
    },
    setFlights(state, flights) {
      // const t0 = performance.now()
      // const t1 = performance.now()
      // console.log('Time taken: ' + (t1 - t0) + ' milliseconds')

      const agents = mergeAgents(state, flights)
      const currency = { ...state.flights.Currency, ...flights.Currency }
      const places = { ...state.flights.Places, ...flights.Places }
      const segments = { ...state.flights.Segments, ...flights.Segments }
      const query = { ...state.flights.Query, ...flights.Query }
      const legs = { ...state.flights.Legs, ...setLegs(flights.Legs, places) }

      const pricingOptions = mergePricingOptions(state, flights)
      const mergedItineraries = mergeItineraries(
        state,
        flights,
        legs,
        pricingOptions,
      )
      const itineraries = mergedItineraries.itineraries
      const outboundOrigins = mergedItineraries.outboundOrigins
      const inboundOrigins = mergedItineraries.inboundOrigins
      const flightStops = mergedItineraries.flightStops
      const carriers = mergedItineraries.carriers
      const scores = mergedItineraries.scores
      const filterCarriers = state.filters.carriers
        ? state.filters.carriers
        : []

      for (const i in itineraries) {
        const itinerary = itineraries[i]

        itinerary.types = new Set()
        itinerary.ourPositions = []
        itinerary.ourPosition = null
        itinerary.ourPrice = null
        itinerary.competitorPosition = null
        itinerary.competitorPrice = null
        itinerary.saving = null
        const bookingOptionsCount = itinerary.BookingOptions.length

        itinerary.BookingOptions = _.sortBy(itinerary.BookingOptions, 'price')
        itinerary.price = itinerary.BookingOptions[0].price
        itinerary.bookingOptionsCount = bookingOptionsCount

        // Price & itinerary types
        itinerary.BookingOptions.forEach((bookingOption, i) => {
          bookingOption.price = 0

          bookingOption.BookingItems.forEach((bookingItem) => {
            bookingOption.price +=
              pricingOptions[bookingItem.PricingId][0].Price

            itinerary.types.add(bookingOption.Type)
          })

          if (bookingOption.OurDeal) {
            itinerary.ourPositions.push(i)
          }
        })

        const setCompetitorPosition = function (ourPosition) {
          if (typeof ourPosition === 'undefined') {
            return 0
          }

          if (bookingOptionsCount === itinerary.ourPositions.length) {
            return
          }

          if (ourPosition + 1 < bookingOptionsCount) {
            return setCompetitorPosition(ourPosition + 1)
          }

          return ourPosition
        }

        if (itinerary.ourPositions) {
          itinerary.ourPosition = itinerary.ourPositions[0]
          if (typeof itinerary.ourPosition !== 'undefined') {
            itinerary.ourPrice =
              itinerary.BookingOptions[itinerary.ourPosition].price
          }
        }

        itinerary.competitorPosition = setCompetitorPosition(
          itinerary.ourPosition,
        )

        if (typeof itinerary.competitorPosition !== 'undefined') {
          itinerary.competitorPrice =
            itinerary.BookingOptions[itinerary.competitorPosition].price

          if (typeof itinerary.ourPosition !== 'undefined') {
            itinerary.saving = itinerary.competitorPrice - itinerary.ourPrice
          }
        }

        // Add carrier to filter options
        const carrierId =
          itinerary.carrierIds.length === 1 ? itinerary.carrierIds[0] : 99999
        const thisCarrier = filterCarriers.find(
          (carrier) => carrier.Id === carrierId,
        )

        if (!thisCarrier) {
          filterCarriers.push({
            Id: carrierId,
            Name: carriers[carrierId].Name,
            MinPrice: itinerary.price,
            active: true,
          })
        } else {
          if (itinerary.price < thisCarrier.MinPrice) {
            thisCarrier.MinPrice = itinerary.price
          }
        }

        // Get min/max duration & prices for scores
        scores.minPrice =
          itinerary.price < scores.minPrice ? itinerary.price : scores.minPrice
        scores.maxPrice =
          itinerary.price > scores.maxPrice ? itinerary.price : scores.maxPrice
        scores.minDuration =
          itinerary.duration < scores.minDuration
            ? itinerary.duration
            : scores.minDuration
        scores.maxDuration =
          itinerary.duration > scores.maxDuration
            ? itinerary.duration
            : scores.maxDuration
        scores.minStops =
          itinerary.stops < scores.minStops ? itinerary.stops : scores.minStops
        scores.maxStops =
          itinerary.stops > scores.maxStops ? itinerary.stops : scores.maxStops

        // Scores
        const durationDifference =
          scores.maxDuration - scores.minDuration === 0
            ? 1
            : scores.maxDuration - scores.minDuration
        const priceDifference =
          scores.maxPrice - scores.minPrice === 0
            ? 1
            : scores.maxPrice - scores.minPrice
        const stopsDifference =
          scores.maxStops - scores.minStops === 0
            ? 1
            : scores.maxStops - scores.minStops

        const durationIndex =
          1 - (itinerary.duration - scores.minDuration) / durationDifference
        const priceIndex =
          1 - (itinerary.price - scores.minPrice) / priceDifference
        const stopsIndex =
          1 - (itinerary.stops - scores.minStops) / stopsDifference

        // itinerary.durationIndex = durationIndex
        // itinerary.priceIndex = priceIndex
        // itinerary.stopsIndex = stopsIndex

        itinerary.score =
          Math.round(
            (100 - (durationIndex * 30 + priceIndex * 60 + stopsIndex * 10)) *
              100,
          ) / 100
      }

      const flightsNew = {
        Agents: agents,
        Carriers: carriers,
        Currency: currency,
        Itineraries: itineraries,
        Legs: legs,
        Places: places,
        PricingOptions: pricingOptions,
        Segments: segments,
        Query: query,
        flightStops,
        outboundOrigins,
        inboundOrigins,
        scores,
        RequestCount: flights.RequestCount,
      }

      Vue.set(state, 'flights', flightsNew)

      let statusMessage = flights.Status.message
      if (
        statusMessage === 'UpdatesComplete' &&
        Object.keys(state.flights.Itineraries).length === 0
      ) {
        statusMessage = 'NoResults'
      }
      Vue.set(state, 'status', statusMessage)

      Vue.set(state.filters, 'carriers', filterCarriers)
    },
    setError(state, error) {
      console.log(error)
      Vue.set(state, 'error', error)
    },
    setCountryId(state, countryId) {
      Vue.set(state.settings, 'countryId', countryId)
    },
    setLanguageId(state, languageId) {
      Vue.set(state.settings, 'languageId', languageId)
    },
    setCurrencyId(state, currencyId) {
      Vue.set(state.settings, 'currencyId', currencyId)
    },
    outboundDepartureTimesMin(state, minutes) {
      Vue.set(state.filters.outbound.origin.departure, 'min', minutes)
    },
    outboundArrivalTimesMin(state, minutes) {
      Vue.set(state.filters.outbound.origin.arrival, 'min', minutes)
    },
    outboundDepartureTimesMax(state, minutes) {
      Vue.set(state.filters.outbound.origin.departure, 'max', minutes)
    },
    outboundArrivalTimesMax(state, minutes) {
      Vue.set(state.filters.outbound.origin.arrival, 'max', minutes)
    },
    inboundDepartureTimesMin(state, minutes) {
      Vue.set(state.filters.inbound.origin.departure, 'min', minutes)
    },
    inboundArrivalTimesMin(state, minutes) {
      Vue.set(state.filters.inbound.origin.arrival, 'min', minutes)
    },
    inboundDepartureTimesMax(state, minutes) {
      Vue.set(state.filters.inbound.origin.departure, 'max', minutes)
    },
    inboundArrivalTimesMax(state, minutes) {
      Vue.set(state.filters.inbound.origin.arrival, 'max', minutes)
    },
    durationMax(state, minutes) {
      Vue.set(state.filters, 'durationMax', minutes)
    },
    toggleStop(state, stop) {
      Vue.set(state.filters.stopTypes, stop, !state.filters.stopTypes[stop])
    },
    setAirlines(state, obj) {
      const airline = state.filters.carriers.find((carrier) => {
        return carrier.Id === obj.carrierId
      })

      Vue.set(airline, 'active', obj.active)
    },
    setAgent(state, obj) {
      const agents = state.filters.agents.find((agent) => {
        return agent.type === obj.type
      })

      Vue.set(agents, 'active', obj.active)
    },
    toggleAirline(state, carrierId) {
      const thisCarrier = state.filters.carriers.find((carrier) => {
        return carrier.Id === carrierId
      })
      thisCarrier.active = !thisCarrier.active

      Vue.set(thisCarrier, 'active', !thisCarrier.active)
    },
    setAirportDestination(state, obj) {
      Vue.set(state.criteria.destination, 'code', obj.code)
      Vue.set(state.criteria.destination, 'name', obj.name)
    },
    setAirportOrigin(state, obj) {
      Vue.set(state.criteria.origin, 'code', obj.code)
      Vue.set(state.criteria.origin, 'name', obj.name)
    },
    setOutboundAirport(state, obj) {
      const airport = state.filters.outbound.origins.find((airport) => {
        return airport.code === obj.code
      })

      Vue.set(airport, 'active', obj.active)
    },
    toggleOutboundAirport(state, airportCode) {
      const airport = state.filters.outbound.origins.find((airport) => {
        return airport.code === airportCode
      })

      Vue.set(airport, 'active', !airport.active)
    },
    setSameOutboundAndInbound(state, value) {
      Vue.set(state.filters, 'sameOutboundAndInbound', value)
    },
    setSameConnectionAirports(state, value) {
      Vue.set(state.filters, 'sameConnectionAirports', value)
    },
    toggleSearchControls(state) {
      state.showSearchControls = !state.showSearchControls
    },
    setSearchControls(state, showControls) {
      state.showSearchControls = showControls
    },
    setCalendarType(state, calendarType) {
      Vue.set(state.criteria, 'calendarType', calendarType)
    },
    setOutboundDate(state, date) {
      Vue.set(state.criteria, 'outboundDate', date)

      if (date === 'anytime') {
        return
      }

      const firstDayOfInboundMonth = moment(date).startOf('month')
      if (
        !state.filters.outboundEarliest ||
        date < state.filters.outboundEarliest
      ) {
        return Vue.set(
          state.filters,
          'outboundEarliest',
          firstDayOfInboundMonth.format('YYYY-MM-DD'),
        )
      }

      const firstDayOfDepartureMonth = moment(
        state.filters.outboundEarliest,
      ).startOf('month')
      if (firstDayOfInboundMonth.isAfter(firstDayOfDepartureMonth)) {
        Vue.set(
          state.filters,
          'outboundEarliest',
          firstDayOfInboundMonth.format('YYYY-MM-DD'),
        )
      }
    },
    setInboundDate(state, date) {
      Vue.set(state.criteria, 'inboundDate', date)

      if (date === 'anytime' || !date) {
        return
      }

      const lastDayOfInboundMonth = moment(date).endOf('month')
      if (!state.filters.inboundLatest || date > state.filters.inboundLatest) {
        return Vue.set(
          state.filters,
          'inboundLatest',
          lastDayOfInboundMonth.format('YYYY-MM-DD'),
        )
      }

      const lastDayOfLatestReturnMonth = moment(
        state.filters.inboundLatest,
      ).endOf('month')
      if (lastDayOfInboundMonth.isBefore(lastDayOfLatestReturnMonth)) {
        Vue.set(
          state.filters,
          'inboundLatest',
          lastDayOfInboundMonth.format('YYYY-MM-DD'),
        )
      }
    },
    setBrowseQuotes(state, data) {
      if (!data) {
        Vue.set(state, 'showSearchControls', false)

        if (!data.anyTime) {
          Vue.set(state.browse, 'flights', {})
          Vue.set(state.browse, 'cheapest', {})
          Vue.set(state, 'status', 'NoResults')
        }

        return
      }

      const dates = data.Dates
      const cheapest = data.Cheapest

      colourPrices(dates)

      if (state.criteria.tripType === 'roundTrip') {
        for (const date in dates) {
          colourPrices(dates[date].inbound)
        }
      }

      if (data.anyTime) {
        Vue.set(state.browse, 'flightsAnyTime', dates)
        Vue.set(state.browse, 'cheapestAnyTime', cheapest)
      } else {
        Vue.set(state.browse, 'flights', dates)
        Vue.set(state.browse, 'cheapest', cheapest)
        Vue.set(state, 'status', 'UpdatesComplete')
      }
    },
    setBrowseId(state, id) {
      Vue.set(state.browse, 'browseId', id)
    },
    setBrowseRoutes(state, data) {
      Vue.set(state, 'routes', data)
    },
    toggleChart(state) {
      Vue.set(state.filters, 'isChart', !state.filters.isChart)
    },
    clearDeals(state) {
      Vue.set(state, 'deals', [])
    },
    setDeals(state, data) {
      let direct = 'direct'
      const deals = {}

      for (const airport of data) {
        const originPlace = airport[0]
        const destinationPlace = airport[1]
        const cheapestDeals = airport[2]
        let cheapestDeal

        if (!cheapestDeals) {
          continue
        }

        if (cheapestDeals.direct.price) {
          cheapestDeal = cheapestDeals.direct

          if (
            cheapestDeals.indirect &&
            cheapestDeals.indirect.price < cheapestDeals.direct.price
          ) {
            cheapestDeal = cheapestDeals.indirect
            direct = 'indirect'
          }
        } else {
          cheapestDeal = cheapestDeals.indirect
          direct = 'indirect'
        }

        const airportCode =
          originPlace === 'BJL' ? destinationPlace : originPlace

        deals[airportCode] = {
          originPlace,
          destinationPlace,
          outboundDate: cheapestDeal.outboundDate,
          inboundDate: cheapestDeal.inboundDate,
          price: cheapestDeal.price,
          direct,
        }
      }

      Vue.set(state, 'deals', deals)
    },
    selectCabinClass(state, cabinClass) {
      Vue.set(state.criteria, 'cabinClass', cabinClass)
    },
    addAdults(state) {
      state.criteria.adults++
    },
    decAdults(state) {
      state.criteria.adults--
    },
    addChild(state) {
      state.criteria.children.push(-1)
    },
    decChild(state) {
      state.criteria.children.pop()
    },
    setChildAge(state, { index, age }) {
      state.criteria.children[index] = age
    },
    setTripType(state, tripType) {
      Vue.set(state.criteria, 'tripType', tripType)

      if (tripType === 'one-way') {
        Vue.set(state.criteria, 'inboundDate', null)
        Vue.set(state.criteria, 'inboundDate', null)
        Vue.set(state.criteria, 'inboundLatest', null)
      }

      if (tripType === 'roundTrip' && state.criteria.inboundDate === null) {
        Vue.set(state.criteria, 'inboundDate', state.criteria.outboundDate)
      }

      const flights = {
        Agents: {},
        Carriers: {},
        Currency: [],
        Itineraries: {},
        Legs: {},
        Places: {},
        PricingOptions: {},
        Segments: {},
        Query: [],
        flightStops: {
          direct: {
            count: 0,
            minPrice: 999999,
          },
          oneStop: {
            count: 0,
            minPrice: 999999,
          },
          twoPlusStops: {
            count: 0,
            minPrice: 999999,
          },
        },
        scores: {
          minDuration: 15000,
          maxDuration: 0,
          minPrice: 15000,
          maxPrice: 0,
          minStops: 10,
          maxStops: 0,
        },
        RequestCount: 0,
      }

      const browse = {
        browseId: 0,
        flights: {},
        flightsAnyTime: {},
        cheapest: {
          direct: [],
          indirect: [],
        },
        cheapestAnyTime: {
          direct: [],
          indirect: [],
        },
      }

      Vue.set(state, 'flights', flights)
      Vue.set(state, 'browse', browse)
      Vue.set(state, 'status', 'Initial')
    },
    setPlace(state, obj) {
      Vue.set(state.criteria, obj.placeType, obj)
    },
    resetFilters(state) {
      Vue.set(state.filters.outbound.origin, 'departure', {
        min: 0,
        max: 1439,
      })
      Vue.set(state.filters.inbound.origin, 'departure', {
        min: 0,
        max: 1439,
      })
      Vue.set(state.filters.outbound.origin, 'arrival', {
        min: 0,
        max: 1439,
      })
      Vue.set(state.filters.inbound.origin, 'arrival', {
        min: 0,
        max: 1439,
      })
      Vue.set(state.filters, 'durationMax', 15000)
      Vue.set(state.filters, 'sameOutboundAndInbound', false)
      Vue.set(state.filters, 'sameConnectionAirports', false)
      Vue.set(state.filters, 'stopTypes', {
        direct: true,
        one_stop: true,
        two_plus_stops: true,
      })
      state.filters.carriers.map((carrier) => {
        carrier.active = true
      })
    },
    sortItineraries(state, sortValue) {
      Vue.set(state.criteria, 'sortValue', sortValue)
    },
    setStatus(state, status) {
      if (status === 'Searching') {
        state.showSearchControls = false

        const flights = {
          Agents: {},
          Carriers: {},
          Currency: [],
          Itineraries: {},
          Legs: {},
          Places: {},
          PricingOptions: {},
          Segments: {},
          Query: [],
          flightStops: {
            direct: {
              count: 0,
              minPrice: 999999,
            },
            oneStop: {
              count: 0,
              minPrice: 999999,
            },
            twoPlusStops: {
              count: 0,
              minPrice: 999999,
            },
          },
          scores: {
            minDuration: 15000,
            maxDuration: 0,
            minPrice: 15000,
            maxPrice: 0,
            minStops: 10,
            maxStops: 0,
          },
          RequestCount: 0,
        }

        Vue.set(state, 'flights', flights)
        Vue.set(state.filters.inbound, 'origins', [])
        Vue.set(state.filters.outbound, 'origins', [])
        Vue.set(state.filters, 'carriers', [])
      }
      if (status === 'Initial') {
        state.showSearchControls = true
      }
      if (status === 'UpdatesComplete') {
        state.showSearchControls = false
      }
      Vue.set(state, 'status', status)
    },
    setScreenWidth(state, screenWidth) {
      Vue.set(state, 'screenWidth', screenWidth)
    },
    toggleDirectOnly(state) {
      Vue.set(state.filters, 'direct', !state.filters.direct)
    },
    setDirectOnly(state, value) {
      Vue.set(state.filters, 'direct', value)
    },
    setMinNights(state, nights) {
      Vue.set(state.filters.nights, 'min', nights)
    },
    setMaxNights(state, nights) {
      Vue.set(state.filters.nights, 'max', nights)
    },
    setOutboundEarliest(state, date) {
      Vue.set(state.filters, 'outboundEarliest', date)
    },
    setInboundLatest(state, date) {
      Vue.set(state.filters, 'inboundLatest', date)
    },
    setItineraryId(state, itineraryId) {
      Vue.set(state, 'itineraryId', itineraryId)
    },
    setTipCounter(state, tip) {
      Vue.set(state.user, 'tipCounter', tip)
    },
    toggleBrowseFilters(state) {
      Vue.set(state.filters, 'showFilters', !state.filters.showFilters)
    },
  },
})
